// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-js": () => import("/opt/build/repo/src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-books-js": () => import("/opt/build/repo/src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentor-js": () => import("/opt/build/repo/src/pages/mentor.js" /* webpackChunkName: "component---src-pages-mentor-js" */),
  "component---src-pages-mentors-js": () => import("/opt/build/repo/src/pages/mentors.js" /* webpackChunkName: "component---src-pages-mentors-js" */),
  "component---src-pages-podcast-js": () => import("/opt/build/repo/src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-podcasts-js": () => import("/opt/build/repo/src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */)
}

